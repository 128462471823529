import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Comic from "@components/partials/ComicPage"

const ComicPage = () => (
  <Layout
    title="Mark McKenna's Heroes & Villains NFT"
    contentClassName="ComicContent px-0"
    type="comic"
  >
    <Comic/>
  </Layout>
)

export default ComicPage
