export const nftData = [
  {
    image: "/comic_page/1.png",
    id: "#0",
    name: "Silver"
  },
  {
    image: "/comic_page/2.png",
    id: "#0",
    name: "Gold"
  },
  {
    image: "/comic_page/5.png",
    id: "#0",
    name: "Cosmic"
  },
  {
    image: "/comic_page/3.png",
    id: "#126",
    name: "Hellfire"
  },
  {
    image: "/comic_page/4.png",
    id: "#1287",
    name: "Merphona"
  },
  {
    image: "/comic_page/6.png",
    id: "#2688",
    name: "Janus the Hungry"
  },
  {
    image: "/comic_page/7.png",
    id: "#2666",
    name: "Sterne"
  },
  {
    image: "/comic_page/8.png",
    id: "#2249",
    name: "Kyoshi"
  },
  {
    image: "/comic_page/9.png",
    id: "#2741",
    name: "Doku Arata"
  },
  {
    image: "/comic_page/10.png",
    id: "#2556",
    name: "Incontress"
  },
  {
    image: "/comic_page/11.png",
    id: "#239",
    name: "Asmodeus"
  },
  {
    image: "/comic_page/12.png",
    id: "#792",
    name: "Razor Reaper"
  },
  {
    image: "/comic_page/13.png",
    id: "#2820",
    name: "Stone Legend"
  },
  {
    image: "/comic_page/14.png",
    id: "#2266",
    name: "Khader the Devout"
  },
  {
    image: "/comic_page/15.png",
    id: "#548",
    name: "Zycatrix"
  },
]
