import React from 'react'
import { nftData } from './data'
import './ComicPage.scss'

const Comic = () => {

  const NFTItem = (props) => {
    const { nft } = props
    return (
      <div className='flex items-center justify-center flex-col text-white text-center'>
        <img src={nft.image} alt={nft.name} />
        <p className='italic text-3xl sm:text-2xl md:text-3xl xl:text-4xl font-extrabold transform -translate-y-1/2'>{nft.id}</p>
        <p className='italic text-3xl sm:text-2xl md:text-3xl xl:text-4xl font-extrabold transform -translate-y-1/2 whitespace-nowrap'>{nft.name}</p>
      </div>
    )
  }

  return (
    <div className='comic-container font-montserrat-bold space-y-12 items-center flex flex-col justify-center pb-12'>
      <div className='flex flex-col lg:flex-row py-12 container'>
        <div className='w-full lg:w-1/2 flex items-center justify-center'>
          <img src='/comic_page/main.png' alt='total' className='md:w-3/4'/>
        </div>
        <div className='flex flex-col items-center lg:items-start justify-center text-white w-full lg:w-1/2 lg:transform -translate-x-12 gap-4 px-8 lg:text-left text-center'>
          <p className='text-4xl lg:text-4xl font-bold italic '>ORIGINS SOURCEBOOK #0</p>
          <p className='text-2xl font-montserrat leading-10'>
            Welcome to the first issue of our community-created character sourcebook. Featuring 12 amazing stories written by our holders themselves, dive head-first into the Heroes & Villains universe. Enjoy!
          </p>
          <a href="https://arweave.net/YgIvFwuDTcSOPIsEsgM47f5A8winVX5ED3eZ0aB_tJE" target="_blank" rel="noreferrer">
            <button className='uppercase italic text-white font-bold border-white border-4 rounded-xl bg-black px-8 py-2 text-2xl lg:text-3xl'>
              Read Issue
            </button>
          </a>
          <a href="https://magiceden.io/marketplace/hvos0" target="_blank" rel="noreferrer">
            <button className='uppercase italic text-white font-bold border-white border-4 rounded-xl bg-black px-8 py-2 text-2xl lg:text-3xl'>
              Browse Marketplace
            </button>
          </a>
        </div>
      </div>
      <div className='flex items-center flex-col container'>
        <div className='border-b-2 border-white px-8 pb-3'>
        <p className='italic text-4xl lg:text-5xl text-center text-white uppercase'>Variant Covers</p>
        </div>
        <div className='grid sm:grid-cols-2 lg:grid-cols-3 items-center'>
          {nftData.map((nft, idx) => (
            <NFTItem nft={nft} key={idx} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Comic
